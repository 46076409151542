import $ from 'jquery';
import 'jquery-validation';

$.validator.setDefaults({
  ignore: [],
  errorPlacement: function (error, element) {
    error.appendTo(element.parent());
  },
});

const $validationForms = $('[data-validate]');

$validationForms.validate({
  errorElement: 'span',
});

$validationForms.on('sending', function () {
  this.classList.add('sending');
});

$validationForms.on('done', function (...attrs) {
  this.classList.remove('sending');
  attrs[0].target.reset();
  formPopUp(attrs[1]);
});

function formPopUp({
  title = 'Thanks!',
  text = 'your message has been sent',
} = {}) {
  const $popup = $('<div/>', { class: 'cf-reveal' });
  const $window = $(
    `<div class="cf-reveal-window"><div class="cf-reveal-title">${title}</div><div class="cf-reveal-text">${text}</div></div>`,
  );
  const $close = $(
    '<button class="cf-reveal-close"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6569 19.8926C19.2663 20.2831 18.6332 20.2831 18.2426 19.8926L14 15.6499L9.75736 19.8926C9.36683 20.2831 8.73367 20.2831 8.34315 19.8926L8.10744 19.6569C7.71692 19.2663 7.71692 18.6332 8.10744 18.2426L12.3501 14L8.10744 9.75736C7.71692 9.36684 7.71692 8.73367 8.10744 8.34315L8.34315 8.10745C8.73367 7.71692 9.36683 7.71692 9.75736 8.10745L14 12.3501L18.2426 8.10745C18.6332 7.71692 19.2663 7.71692 19.6569 8.10745L19.8926 8.34315C20.2831 8.73367 20.2831 9.36684 19.8926 9.75736L15.6499 14L19.8926 18.2426C20.2831 18.6332 20.2831 19.2663 19.8926 19.6569L19.6569 19.8926Z" fill="#141416"/></svg></button>',
  );

  $popup.append($window.append($close));

  $popup.css('display', 'flex').hide();

  $('body').append($popup.fadeIn());

  $close.on('click', function () {
    $popup.fadeOut(200, () => {
      $popup.remove();
    });
  });
}

$validationForms.on('submit', function (e) {
  e.preventDefault();
  $(this).trigger('submitValid');
});

// Sending form example
$('#cf').on('submitValid', function () {
  const $form = $(this);
  const data = $form.serializeArray();

  $form.trigger('sending');
  console.log(data);

  // Ajax function
  setTimeout(() => {
    $form.trigger('done', {
      title: 'Thanks!',
      text: 'your message has been sent',
    });
  }, 2000);
});

// Checkbox
$('[type="checkbox"] + label').on('keypress', function (ev) {
  ev.preventDefault();
  const keys = [' ', 'Enter'];

  if (keys.includes(ev.key)) {
    $(this).prev('[type="checkbox"]').click();
  }
});
